import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/article-page-layout.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Intro = makeShortcode("Intro");
const Body = makeShortcode("Body");
const Outro = makeShortcode("Outro");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Intro mdxType="Intro">
      <p><a parentName="p" {...{
          "href": "/notes/2022-01-31-pattern-matching"
        }}>{`前回`}</a>{`に引き続き、David C. Baker著、The Business of Expertiseについて理解したことをまとめていく。`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://amzn.to/3s4J0LX",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer"
          }}>{`The Business of Expertise by David C. Baker`}</a></li>
      </ul>
      <p>{`前回は、Pattern Matchingでexpertiseが培われる的な話であった。Pattern Matchingの話には続きがあって、この本で書かれているのは以下のようなこと。`}</p>
      <ul>
        <li parentName="ul">{`A: 類似した機会がPattern Matchingを可能にする`}</li>
        <li parentName="ul">{`B: 正しいPositioningがパターンを見えやすくする`}</li>
      </ul>
      <p>{`これは何を言っているのか。`}</p>
    </Intro>
    <Body mdxType="Body">
      <h2 {...{
        "id": "仕事以外の例"
      }}>{`仕事以外の例`}</h2>
      <p>{`Positioningってどういうこと？としばらく分からなかったのだけれども、これは`}<a parentName="p" {...{
          "href": "https://economictimes.indiatimes.com/definition/positioning",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`マーケティング用語`}</a>{`っぽかった。それは置いておくとして、このABを、例えば虫捕りで例えるとこういうことかと理解した。`}</p>
      <ul>
        <li parentName="ul">{`A: 家の近くの草むらにバッタがたくさん居ると、バッタに詳しくなる`}</li>
        <li parentName="ul">{`B: バッタがたくさんいる草むらの近くに引っ越すと、バッタをたくさん見るようになる`}</li>
      </ul>
      <p>{`あと、例えば音楽を聴くので、テクノに詳しくなるみたいな例でいうと。`}</p>
      <ul>
        <li parentName="ul">{`A: テクノばかり聴いていると、どういう音楽がテクノと呼ばれているか分かるようになる`}</li>
        <li parentName="ul">{`B: 家の近くにテクノミュージック中心のレコード屋があると、テクノばかり聴くようになる`}</li>
      </ul>
      <h2 {...{
        "id": "仕事でどう考える"
      }}>{`仕事でどう考える`}</h2>
      <p>{`これが仕事……というかビジネスだとどう考えたらいいのか。`}</p>
      <p>{`例えば、「不動産業界に詳しいWeb屋」というのを考える。不動産関連のクライアントと多く仕事をしていれば、自然と不動産のことも詳しくなっていくはず。これがAの`}</p>
      <blockquote>
        <p parentName="blockquote">{`類似した機会がPattern Matchingを可能にする`}</p>
      </blockquote>
      <p>{`ということ。でも、多くの人は不動産関連の仕事なんて相談されないかと思う。だから、このAを可能にするのは、もしかしたらそういうチャンスがあったからかもしれない。ただ、仮にそういうチャンスが微塵でもあったとしても、次のBを意識しないとなかなか形にはならないということかと思われた。そのBというのは……`}</p>
      <blockquote>
        <p parentName="blockquote">{`正しいPositioningがパターンを見えやすくする`}</p>
      </blockquote>
      <p>{`ということで、これは仕事で言えば、「ウチは不動産専門でWebをやってます」と宣言することに違いない。まぁこれは`}<a parentName="p" {...{
          "href": "https://servithink.co.jp/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`事実`}</a>{`を書いているんですが。というかフツーに仕事でお付き合いがあるクライアントだとか、自分の周りでも、うまくいっているのって結構こういうパターンっぽい気がする。`}</p>
      <h2 {...{
        "id": "gatsby屋"
      }}>{`Gatsby屋`}</h2>
      <p>{`ところで私、「Gatsby屋」っていう商売を初めたんですよ。そう、このサイトは`}<a parentName="p" {...{
          "href": "https://www.gatsbyjs.com/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`Gatsby`}</a>{`というフレームワークで作られてるんですが。これが現状のWeb開発の色んなことを解決できることに気付きましてね。でもこれ結構知識が必要なんです。`}</p>
      <p>{`だからこれからはGatsby一本でやっていくことにしたんですよね。ああそう、色んな所が「ウチもGatsbyはできますよ」っていうかもしれないんですけど、たぶんウチほど突っ込んでやっているところは無いと思うんで、まぁ機会があれば相談にのりますよ。`}</p>
      <p>{`……というのはフィクションなんですが、仮にそういう宣言して、Gatsbyが問題を解決するようなケースを繰り返していき、「ウチはGatsby専門」と言い続け、そういう仕事だけを選んで請けるようにしていけば、Gatsbyで解決できそうな話が持ちかけられやすくなる…… というようなことを、多分この本では言っている。`}</p>
    </Body>
    <Outro mdxType="Outro">
      <p>{`これを虫捕りの話に戻せば、バッタに詳しくなりたければ、バッタのいる草むらの近くに意識して引っ越せ。テクノで言えば、テクノのレコード置いてるレコード屋の近くに引っ越せ。ってことになる。そう。ビジネスでもそうするといいんじゃないのということ。`}</p>
      <p>{`そしてこの本では、観察をすることでPatternが見えやすくなる。そうして考えたこと／見つけたことを、誰かと話すしたり、何か書いたり、人にアドバイスしたりする際に使えと言っている。`}</p>
      <p>{`そう、そうやってブログなりどこかで話したり、普通に仕事で「あ、それならウチでそういうケースでうまく解決できたことがあるので、お手伝いできますよ。その時は……」などというのを繰り返せと、まぁそういうことなんだなー。`}</p>
    </Outro>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      